import {
    Box,
    Center,
    Container,
    HStack,
    IconButton,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react'
import { storage } from '../../firebaseManager'
import { ref, listAll, StorageReference } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Package } from '../../model'
import { GetTargetPackage } from '../../firebaseManager/Communicator/PackageCommunicator'
import {
    ArrowLeftIcon,
    CopyIcon,
    LockIcon,
    QuestionOutlineIcon,
    UnlockIcon,
    ViewIcon,
    ViewOffIcon,
} from '@chakra-ui/icons'
import { OpenDocButton } from '../../components/OpenDocButton'
import { DeleteDocButton } from '../../components/DeleteDocButton'
import { UploadFiles } from '../../components/UploadFiles'
import moment from 'moment'
import { DownloadDocButton } from '../../components/DownloadDocButton'
import { OpenDocButtonViaFunction } from '../../components/OpenDocButtonViaFunction'
import NavBar from '../../components/Layout/NavBar'
import { ModifyPackageButton } from '../../components/ModifyPackageButton'

export const PackageEdit = () => {
    let { id } = useParams<'id'>()
    const toast = useToast()
    const navigate = useNavigate()

    //package info
    const [targetPackageId, setTargetPackageId] = useState<string | undefined>(
        id
    )
    const [targetPackage, setTargetPackage] = useState<Package | undefined>()
    const [listDocDansStorage, setListDocDansStorage] = useState<
        StorageReference[]
    >([])

    const [loadingPackage, setLoadingPackage] = useState<boolean>(false)
    const [packageError, setPackageError] = useState<boolean>(false)
    const [loadingFilesList, setLoadingFilesList] = useState<boolean>(false)

    const [showPassword, setShowPassword] = useState<boolean>(false)

    function refreshTargetPackage() {
        if (!targetPackageId) {
            toast({
                title: "Information de l'id de package cible manquant",
                description: '',
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
        setLoadingPackage(true)
        GetTargetPackage(targetPackageId!)
            .then((result) => {
                setTargetPackage(result)
                setLoadingPackage(false)

                ListFilesOnStorage()
            })
            .catch((e) => {
                console.log('erreur ' + e.message)

                setTargetPackage(undefined)
                setLoadingPackage(false)
                setPackageError(true)
            })
    }

    function ListFilesOnStorage() {
        if (!targetPackageId) {
            toast({
                title: "Information de l'id de package cible manquant",
                description: '',
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
        console.log('list files')
        setLoadingFilesList(true)

        // Create a reference under which you want to list
        const listRef = ref(storage, 'documents/' + targetPackageId! + '/')

        // Find all the prefixes and items.
        listAll(listRef)
            .then((res) => {
                setListDocDansStorage(res.items)
                setLoadingFilesList(false)
            })
            .catch((error) => {
                console.log('error : ' + error)
                setLoadingFilesList(false)
            })
    }

    function GetPublicLink() {
        return window.location.origin + '/open/' + targetPackage?.id
    }

    async function CopyPublicLinkToClipboard() {
        const fullPath = GetPublicLink()

        console.log('copy to clipboard :' + fullPath)

        try {
            await navigator.clipboard.writeText(fullPath)
            toast({
                title: 'Lien public copié ',
                description: '',
                status: 'success',
                duration: 2000,
                isClosable: true,
            })
        } catch (error: any) {
            toast({
                title: 'Erreur lors de la copie',
                description: error.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        refreshTargetPackage()
    }, [])

    return (
        <>
            <NavBar />
            <Container maxW="container.lg">
                <Box
                    onClick={(e) => navigate('/')}
                    m="20px"
                    p="20px"
                    borderRadius={'20px'}
                    backgroundColor={'#1F2937'}
                    w={'fit-content'}
                >
                    <HStack>
                        <ArrowLeftIcon mr={'10px'} />
                        <Text> Retour a la liste</Text>
                    </HStack>
                </Box>
                {packageError ? (
                    <Box
                        m="20px"
                        p="20px"
                        borderRadius={'20px'}
                        backgroundColor={'#1F2937'}
                    >
                        <Center mb={'15px'}>
                            <QuestionOutlineIcon boxSize={12} />
                        </Center>
                        <Center>
                            <Text>
                                Nous ne trouvons pas cette boite documentaire.
                            </Text>
                        </Center>
                        <Center>
                            <Text>
                                Soit elle n'existe pas, soit vous ne possèdez
                                pas les droits.
                            </Text>
                        </Center>
                    </Box>
                ) : (
                    <>
                        {loadingPackage && (
                            <>
                                <Box
                                    m="20px"
                                    p="20px"
                                    borderRadius={'20px'}
                                    backgroundColor={'#1F2937'}
                                >
                                    <Center mb={'15px'}>
                                        <Spinner size={'xl'}></Spinner>
                                    </Center>
                                    <Center>
                                        <Text>
                                            Chargement des informations de la
                                            boite documentaire ...
                                        </Text>
                                    </Center>
                                </Box>
                            </>
                        )}
                        {!loadingPackage && (
                            <>
                                <Center>
                                    <Text fontSize={'40px'} mr="12px">
                                        {targetPackage?.nom}
                                    </Text>{' '}
                                    {targetPackage?.havepassword ? (
                                        <LockIcon
                                            color={'green'}
                                            fontSize={'23px'}
                                        ></LockIcon>
                                    ) : (
                                        <UnlockIcon
                                            color={'orange'}
                                            fontSize={'23px'}
                                        ></UnlockIcon>
                                    )}
                                </Center>
                                <Box
                                    m="20px"
                                    p="20px"
                                    borderRadius={'20px'}
                                    backgroundColor={'#1F2937'}
                                >
                                    <ModifyPackageButton
                                        packageItem={targetPackage!}
                                        onSucess={refreshTargetPackage}
                                    />
                                    <Text fontSize={'20px'} mb={'20px'}>
                                        Information de la boite documentaire{' '}
                                    </Text>
                                    <Text mb={'20px'}>
                                        Voir la boite en tant que lecteur :{' '}
                                        <IconButton
                                            as="a"
                                            href={'/open/' + targetPackage?.id}
                                            target="_blank"
                                            ml="8px"
                                            aria-label=""
                                            fontSize={'16px'}
                                            color={'white'}
                                            backgroundColor={'#111827'}
                                        >
                                            <ViewIcon></ViewIcon>
                                        </IconButton>
                                    </Text>
                                    <Text mb={'20px'}>
                                        Copier le lien public partageable :{' '}
                                        <b>{GetPublicLink()}</b>
                                        <IconButton
                                            onClick={(e) => {
                                                CopyPublicLinkToClipboard()
                                            }}
                                            ml="8px"
                                            aria-label=""
                                            fontSize={'16px'}
                                            color={'white'}
                                            backgroundColor={'#111827'}
                                        >
                                            <CopyIcon></CopyIcon>
                                        </IconButton>
                                    </Text>
                                    <Text mb="10px">
                                        {targetPackage?.havepassword ? (
                                            <>
                                                Un mot de passe a été défini :{' '}
                                                <Box
                                                    as="span"
                                                    color={'white'}
                                                    backgroundColor={'#111827'}
                                                    py={'5px'}
                                                    px={'10px'}
                                                    borderRadius={'5px'}
                                                    onClick={() =>
                                                        setShowPassword(true)
                                                    }
                                                >
                                                    {showPassword ? (
                                                        <>
                                                            {
                                                                targetPackage?.password
                                                            }{' '}
                                                        </>
                                                    ) : (
                                                        <>
                                                            Cliquez pour voir le
                                                            mot de passe
                                                        </>
                                                    )}
                                                </Box>
                                                {showPassword && (
                                                    <>
                                                        {' '}
                                                        <IconButton
                                                            onClick={(e) => {
                                                                setShowPassword(
                                                                    false
                                                                )
                                                            }}
                                                            ml="10px"
                                                            aria-label=""
                                                            fontSize={'12px'}
                                                            color={'white'}
                                                            backgroundColor={
                                                                '#111827'
                                                            }
                                                        >
                                                            <ViewOffIcon></ViewOffIcon>
                                                        </IconButton>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                La boite n'est pas protégé par
                                                un mot de passe
                                            </>
                                        )}
                                    </Text>

                                    <Text>
                                        Identifiant unique: {targetPackage?.id}
                                    </Text>
                                    <Text>
                                        Créé le{' '}
                                        {moment(
                                            targetPackage?.creationDate
                                        ).format('DD-MM-YYYY à hh:mm:ss')}
                                    </Text>
                                </Box>

                                {loadingFilesList && (
                                    <>
                                        <Box
                                            m="20px"
                                            p="20px"
                                            borderRadius={'20px'}
                                            backgroundColor={'#1F2937'}
                                        >
                                            <Center mb={'15px'}>
                                                <Spinner size={'xl'}></Spinner>
                                            </Center>
                                            <Center>
                                                <Text>
                                                    Chargement des documents du
                                                    package ...
                                                </Text>
                                            </Center>
                                        </Box>
                                    </>
                                )}
                                {!loadingFilesList && (
                                    <Box
                                        m="20px"
                                        p="20px"
                                        borderRadius={'20px'}
                                        backgroundColor={'#1F2937'}
                                    >
                                        <Text fontSize={'20px'} mb={'20px'}>
                                            Liste des documents existants dans
                                            la boite
                                        </Text>

                                        {listDocDansStorage.length === 0 && (
                                            <Center>
                                                Aucun documents présents pour le
                                                moment ...
                                            </Center>
                                        )}
                                        {listDocDansStorage.length > 0 &&
                                            listDocDansStorage.map(
                                                (doc, index) => {
                                                    return (
                                                        <Box
                                                            ml="10px"
                                                            mb="5px"
                                                            key={doc.fullPath}
                                                        >
                                                            {doc.name.substring(
                                                                14
                                                            )}
                                                            <DeleteDocButton
                                                                fullPath={
                                                                    doc.fullPath
                                                                }
                                                                onDelete={() => {}}
                                                            />
                                                            <OpenDocButton
                                                                fullPath={
                                                                    doc.fullPath
                                                                }
                                                            />
                                                            <OpenDocButtonViaFunction
                                                                packageId={
                                                                    targetPackageId!
                                                                }
                                                                docName={
                                                                    doc.name
                                                                }
                                                                password={
                                                                    'edede'
                                                                }
                                                            />
                                                            <DownloadDocButton
                                                                packageId={
                                                                    targetPackageId!
                                                                }
                                                                docName={
                                                                    doc.name
                                                                }
                                                                password={
                                                                    'edede'
                                                                }
                                                            />
                                                        </Box>
                                                    )
                                                }
                                            )}
                                    </Box>
                                )}
                                {targetPackageId && (
                                    <UploadFiles
                                        targetPackageId={targetPackageId!}
                                        onSucessFinal={() =>
                                            ListFilesOnStorage()
                                        }
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </Container>
        </>
    )
}
