/*
 * Class d'intération avec firestore pour les éléments : Entreprise
 */
import { Package } from '../../../model'
import { db } from '../..'
import {
    collection,
    query,
    getDocs,
    where,
    documentId,
    orderBy,
    limit,
    doc,
    deleteDoc,
    updateDoc,
} from 'firebase/firestore'

export async function GetTargetPackage(
    packageId: string
): Promise<Package | undefined> {
    const q = query(
        collection(db, 'packages'),
        where(documentId(), '==', packageId),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as Package
    }
    return undefined
}

export async function GetAllMyPackages(
    actualUserUid: string
): Promise<Package[]> {
    const q = query(
        collection(db, 'packages'),
        where('utilisateurProprietaire', '==', actualUserUid),
        orderBy('creationDate', 'desc')
    )
    const querySnapshot = await getDocs(q)
    const allPackages: Package[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Package
    })
    return allPackages
}

export async function DeletePackage(packageId: string) {
    await deleteDoc(doc(db, 'packages', packageId))
}

export async function UpdatePackagePassword(
    packageId: string,
    haspassword: boolean,
    password: string
): Promise<void> {
    await updateDoc(doc(db, 'packages/' + packageId), {
        havepassword: haspassword,
        password: password,
    })
}
