/*
 * Ensemble de fonction annexes et utilitaires
 */

/**
 * Regex permetant de controler si il y a des espaces
 */
export const spaceRegExp = /^[\S]*$/
/**
 * Regex permetant de controler un numéro de telephone
 */
export const phoneRegExp = /^(0|(00|\+)33)[67][0-9]{8}$/

export const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function BuildUrlDocFunction(
    packageId: string,
    docName: string,
    password: string
) {
    return (
        '/api/download_docs?packageId=' +
        encodeURI(packageId!) +
        '&password=' +
        password +
        '&doc=' +
        encodeURI(docName)
    )
}
