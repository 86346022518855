import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ChakraProvider, ThemeConfig } from '@chakra-ui/react'
import { PackageEdit } from './pages/PackageEdit'
import { extendTheme, Box } from '@chakra-ui/react'
import { NotFound } from './pages/NotFound'
import { Footer } from './components/Layout/Footer'
import { PackageList } from './pages/PackageList'
import { AuthContextProvider } from './auth/AuthContextProvider'
import { Login } from './pages/Authentification/Login'
import { ResetPassword } from './pages/Authentification/ResetPassword'
import { ActionFirebase } from './pages/Authentification/ActionFirebase'
import { Logout } from './pages/Authentification/Logout'
import { PackageViewer } from './pages/PackageViewer'

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
    base: '0px',
    sm: '365px',
    md: '770px',
    lg: '1120px',
}

const config: ThemeConfig = {}
// 3. Extend the theme
const theme = extendTheme({ breakpoints, config })
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <Router>
            <ChakraProvider theme={theme}>
                <AuthContextProvider>
                    <App />
                </AuthContextProvider>
            </ChakraProvider>
        </Router>
    </React.StrictMode>
)

function App() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
                backgroundColor: 'black',
            }}
        >
            <Box w={'100%'} bg={'#111827'} color={'white'} pb="64px">
                <Routes>
                    <Route path="/" Component={PackageList} />
                    <Route path="/PackageEdit/:id" Component={PackageEdit} />
                    <Route path="/open/:id" Component={PackageViewer} />

                    <Route path="/login" Component={Login} />
                    {/*<Route path="/create_account" Component={CreateAccount} />*/}
                    <Route path="/reset_password" Component={ResetPassword} />
                    <Route path="/action_fb" Component={ActionFirebase} />
                    <Route path="/logout" Component={Logout} />

                    <Route path="*" Component={NotFound} />
                </Routes>
                <Footer />
            </Box>

            <div
                style={{
                    marginTop: 'auto',
                }}
            ></div>
        </div>
    )
}
