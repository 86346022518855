/*
 * Formulaire autonome et réutilisable permettant de modifier le password client.
 * 2 usages possible :
 *   - mode standard sans paramètre, l'utilisateur doit étre connecté
 *   - mode OOB, avec le code du liens fournis par mail suite a la demande réinitialisation de password.
 */

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton,
    Button,
    Text,
} from '@chakra-ui/react'

import { CreationEditionPackage } from '../Formulaire/CreationEditionPackage'
import { AddIcon } from '@chakra-ui/icons'

interface Props {
    onCreatePackage: () => void
    mode: string
}

export const ModalCreatePackage = (props: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            {props.mode === 'button' && (
                <Button onClick={onOpen}>
                    <AddIcon></AddIcon>
                    <Text ml={'10px'}>Créer une boite</Text>
                </Button>
            )}
            {props.mode === 'icon' && (
                <IconButton
                    ml="8px"
                    aria-label=""
                    fontSize={'16px'}
                    color={'#C05746'}
                    onClick={onOpen}
                    backgroundColor={'#1F2937'}
                >
                    <AddIcon color={'white'}></AddIcon>
                </IconButton>
            )}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Création d'une boite documentaire</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mb="20px">
                        <CreationEditionPackage
                            onSucess={() => {
                                onClose()
                                props.onCreatePackage()
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
