import { useContext, useEffect, useState } from 'react'
import { GetAllMyPackages } from '../../firebaseManager/Communicator/PackageCommunicator'
import {
    Center,
    Text,
    Box,
    VStack,
    Container,
    Heading,
    Spinner,
    IconButton,
    HStack,
    Button,
} from '@chakra-ui/react'
import { Package } from '../../model'
import { AuthContext } from '../../auth/AuthContextProvider'
import { ModalCreatePackage } from '../../components/ModalCreatePackage'
import { useNavigate } from 'react-router-dom'
import { AddIcon, LockIcon, UnlockIcon, ViewIcon } from '@chakra-ui/icons'
import NavBar from '../../components/Layout/NavBar'

import { DeletePackageButton } from '../../components/DeletePackageButton'

export const PackageList = () => {
    const navigate = useNavigate()
    const [allPackages, setAllPackages] = useState<Package[] | null>()
    const [loadingList, setLoadingList] = useState<boolean>(false)

    const authentification = useContext(AuthContext)

    function refreshAllPackages() {
        setLoadingList(true)

        GetAllMyPackages(authentification.firebaseUser!.uid)
            .then((result) => {
                setAllPackages(result)
                setLoadingList(false)
            })
            .catch((e) => {
                setAllPackages(null)
                setLoadingList(false)
            })
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            refreshAllPackages()
        }
    }, [authentification])

    return (
        <>
            <NavBar />
            <Container maxW="container.lg">
                <Center mt="20px">
                    <Heading>Liste des boites documentaires</Heading>
                </Center>

                <Box
                    m="20px"
                    p="20px"
                    borderRadius={'20px'}
                    backgroundColor={'#1F2937'}
                >
                    <Text>
                        Retrouvez ici l'ensemble des boites documentaires
                        actives.
                    </Text>
                    <Text>
                        Une boite documentaire est un espace partageable dans
                        lequel il est possible de déposer des documents.
                    </Text>
                    <Text>
                        Chaques boites possèdent une URL publique (lien web) que
                        l'on peux partager à notre destinataire.
                    </Text>
                    <Text>
                        Il est possible de définir un mot de passe, l'accès aux
                        documents sera alors bloqué tant que le destinataire ne
                        rentre pas le mot de passe.
                    </Text>
                </Box>
                <ModalCreatePackage
                    onCreatePackage={refreshAllPackages}
                    mode="icon"
                />
                {loadingList && (
                    <>
                        <Box
                            m="20px"
                            p="20px"
                            borderRadius={'20px'}
                            backgroundColor={'#1F2937'}
                        >
                            <Center mb={'15px'}>
                                <Spinner size={'xl'}></Spinner>
                            </Center>
                            <Center>
                                <Text> Chargement des packages ...</Text>
                            </Center>
                        </Box>
                    </>
                )}
                {!loadingList && allPackages && allPackages?.length > 0 && (
                    <VStack mt="20px">
                        {allPackages?.map((packageItem, index) => {
                            return (
                                <HStack
                                    marginBottom={'10px'}
                                    w={'100%'}
                                    key={index}
                                >
                                    <Box
                                        key={index}
                                        bg="white"
                                        w={'100%'}
                                        color="#FFFFFF"
                                        backgroundColor={'#1F2937'}
                                        borderRadius={'10px'}
                                        height={'60px'}
                                        verticalAlign={'center'}
                                    >
                                        <Text
                                            fontSize={'25px'}
                                            ml={'20px'}
                                            mt={'10px'}
                                            float={'left'}
                                        >
                                            <b>{packageItem.nom}</b>
                                            {packageItem?.havepassword ? (
                                                <LockIcon
                                                    ml={'10px'}
                                                    color={'green'}
                                                    fontSize={'20px'}
                                                ></LockIcon>
                                            ) : (
                                                <UnlockIcon
                                                    ml={'10px'}
                                                    color={'orange'}
                                                    fontSize={'20px'}
                                                ></UnlockIcon>
                                            )}
                                        </Text>

                                        <Text
                                            fontSize={'15px'}
                                            mr={'20px'}
                                            mt={'20px'}
                                            float={'right'}
                                        >
                                            Créé le{' '}
                                            {new Date(
                                                packageItem.creationDate
                                            ).getDate() +
                                                '/' +
                                                (new Date(
                                                    packageItem.creationDate
                                                ).getMonth() +
                                                    1) +
                                                '/' +
                                                new Date(
                                                    packageItem.creationDate
                                                ).getFullYear()}
                                        </Text>
                                    </Box>
                                    <IconButton
                                        onClick={(e) => {
                                            navigate(
                                                '/PackageEdit/' + packageItem.id
                                            )
                                        }}
                                        ml="8px"
                                        aria-label=""
                                        fontSize={'16px'}
                                        color={'green'}
                                        backgroundColor={'white'}
                                    >
                                        <ViewIcon></ViewIcon>
                                    </IconButton>
                                    <DeletePackageButton
                                        packageId={packageItem.id}
                                        packageName={packageItem.nom!}
                                        onDelete={() => refreshAllPackages()}
                                    />
                                </HStack>
                            )
                        })}
                    </VStack>
                )}
                {!loadingList &&
                    (!allPackages || allPackages?.length === 0) && (
                        <>
                            <Box
                                m="20px"
                                p="20px"
                                borderRadius={'20px'}
                                backgroundColor={'#1F2937'}
                            >
                                <Center mb={'15px'}>
                                    <Text>
                                        Vous ne possèdez aucunes boites pour le
                                        moment
                                    </Text>
                                </Center>
                                <Center>
                                    <ModalCreatePackage
                                        onCreatePackage={refreshAllPackages}
                                        mode="button"
                                    />
                                </Center>
                            </Box>
                        </>
                    )}
            </Container>
        </>
    )
}
