/*
 * Page dédié a l'authentification
 */
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import {
    Flex,
    Text,
    FormControl,
    FormLabel,
    Heading,
    Link as ChakraLink,
    Center,
    Input,
    Stack,
    Image,
    Box,
    Button,
} from '@chakra-ui/react'
import { spaceRegExp } from '../../../utils/helpers'
import { FormikProvider, useFormik } from 'formik'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { useToast } from '@chakra-ui/react'

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .matches(spaceRegExp, "Le mail ne doit pas contenir d'espaces")
        .email('Format du mail invalide')
        .required('Mail requis'),
    password: yup.string().required('Mot de passe requis'),
})

export type LoginValue = {
    email: string
    password: string
}

export const Login = () => {
    const toast = useToast()

    const formikLogin = useFormik({
        validationSchema,
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: (values: LoginValue, actions) => {
            const auth = getAuth()
            signInWithEmailAndPassword(auth, values.email, values.password)
                .then((userCredential) => {
                    actions.setSubmitting(false)
                    actions.resetForm()
                    toast({
                        title: 'Bonjour !',
                        description: 'Redirection vers votre espace',
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                    })
                })
                .catch((error) => {
                    const errorCode = error.code
                    var messageError =
                        'Une erreur a eu lieu, vérifiez vos identifiant ou réessayez plus tard.'
                    switch (errorCode) {
                        case 'auth/invalid-login-credentials':
                            messageError = 'identifiant de connexion invalide'
                            break
                        default:
                            console.log(
                                'erreur non prise en charge par le toast :' +
                                    errorCode
                            )
                            break
                    }
                    toast({
                        title: 'Échec de connexion',
                        description: messageError,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                    actions.setSubmitting(false)
                })
        },
    })

    return (
        <>
            <Stack minH={'80vh'} direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Box
                        backgroundColor={'white'}
                        color={'black'}
                        p={'70px'}
                        borderRadius={'40px'}
                    >
                        <Stack spacing={4} w={'full'} maxW={'md'}>
                            <Center>
                                <Image src="logo.png" w={'200px'} h={'200px'} />
                            </Center>
                            <Center>
                                <Heading fontSize={'2xl'}>
                                    Connexion à mon compte
                                </Heading>
                            </Center>
                            <FormikProvider value={formikLogin}>
                                <FormControl id="email">
                                    <FormLabel>Adresse mail</FormLabel>
                                    <Input
                                        color={'black'}
                                        name="email"
                                        type="email"
                                        onChange={formikLogin.handleChange}
                                        value={formikLogin.values.email}
                                    />
                                </FormControl>
                                <FormControl id="password">
                                    <FormLabel>Mot de passe</FormLabel>
                                    <Input
                                        color={'black'}
                                        name="password"
                                        type="password"
                                        onChange={formikLogin.handleChange}
                                        value={formikLogin.values.password}
                                    />
                                </FormControl>
                                <Stack spacing={6}>
                                    <Stack
                                        direction={{
                                            base: 'column',
                                            sm: 'row',
                                        }}
                                        align={'start'}
                                        justify={'space-between'}
                                    >
                                        <Link to="/reset_password">
                                            <Text color={'blue.500'}>
                                                Mot de passe oublié ?
                                            </Text>
                                        </Link>
                                    </Stack>

                                    <Button
                                        onClick={formikLogin.submitForm}
                                        isDisabled={
                                            !formikLogin.isValid ||
                                            !formikLogin.dirty
                                        }
                                        isLoading={formikLogin.isSubmitting}
                                    >
                                        Connexion
                                    </Button>
                                </Stack>
                            </FormikProvider>
                        </Stack>
                    </Box>
                </Flex>
            </Stack>
        </>
    )
}
