import {
    Box,
    Button,
    Center,
    Input,
    Progress,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react'
import { storage } from '../../firebaseManager'
import { ref, uploadBytes } from 'firebase/storage'
import { useState } from 'react'
import { CheckIcon } from '@chakra-ui/icons'
type PushFile = {
    file: File
    isLoading: boolean
    isPushed: boolean
    isOnError: boolean
}

export type Props = {
    targetPackageId: string
    onSucess?: () => void
    onSucessFinal?: () => void
}

export const UploadFiles = ({
    targetPackageId,
    onSucess,
    onSucessFinal,
}: Props) => {
    // Upload info
    const [files, setfiles] = useState<PushFile[]>([])
    const [progress, setProgress] = useState(0)
    const toast = useToast()

    const uploadFilesToStorage = async () => {
        if (!targetPackageId) {
            toast({
                title: 'Information de package cible manquant',
                description: '',
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
            return
        }
        if (files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                const uploadRef = ref(
                    storage,
                    'documents/' +
                        targetPackageId +
                        '/' +
                        Date.now() +
                        '_' +
                        files[i].file.name.replaceAll(' ', '_')
                )
                setProgress(0)
                if (files[i].isPushed) {
                    setProgress(Math.round((100 / files.length) * (i + 1)))
                } else {
                    const tempArray = [...files]
                    tempArray[i].isLoading = true
                    setfiles(tempArray)

                    await uploadBytes(uploadRef, files[i].file).then(
                        // eslint-disable-next-line no-loop-func
                        (snapshot) => {
                            setProgress(
                                Math.round((100 / files.length) * (i + 1))
                            )
                            tempArray[i].isLoading = false
                            tempArray[i].isPushed = true
                            setfiles(tempArray)

                            if (onSucess) onSucess()
                        }
                    )
                }
            }
            if (onSucessFinal) onSucessFinal()
        }
    }

    return (
        <Box
            m="20px"
            p="20px"
            borderRadius={'20px'}
            backgroundColor={'#1F2937'}
        >
            <Text fontSize={'20px'}>Ajouter des documents</Text>
            <Center>
                <Input
                    w={'400px'}
                    p="5px"
                    m={'30px'}
                    multiple
                    type="file"
                    onChange={(e) => {
                        if (
                            e.currentTarget.files &&
                            e.currentTarget.files.length > 0
                        ) {
                            var filetemp = e.currentTarget.files
                            var filetempArray: PushFile[] = []
                            for (var i = 0; i < filetemp.length; i++) {
                                filetempArray.push({
                                    file: filetemp[i],
                                    isLoading: false,
                                    isPushed: false,
                                    isOnError: false,
                                })
                            }
                            setfiles(filetempArray)
                            setProgress(0)
                        }
                    }}
                ></Input>
            </Center>
            <Box>
                {files &&
                    files.map((file) => {
                        return (
                            <Box
                                w={'280px'}
                                m="10px"
                                p="20px"
                                borderRadius={'20px'}
                                backgroundColor={'#111827'}
                                color={file.isPushed ? 'green' : 'orange'}
                                float={'left'}
                            >
                                <Center>
                                    <b> {file.file.name}</b>
                                </Center>
                                {!file.isPushed && !file.isLoading && (
                                    <Box mt="10px">
                                        <Center>
                                            <Text fontSize={'10x'}>
                                                En attente d'envoi.
                                            </Text>
                                        </Center>
                                    </Box>
                                )}
                                {file.isLoading && (
                                    <Box mt="10px">
                                        <Center>
                                            <Spinner
                                                m="10px"
                                                size={'xl'}
                                                thickness="5px"
                                            ></Spinner>
                                        </Center>
                                        <Center>
                                            <Text fontSize={'10x'}>
                                                Téléchargement en cours ...
                                            </Text>
                                        </Center>
                                    </Box>
                                )}
                                {file.isPushed && (
                                    <Box>
                                        <Center>
                                            <CheckIcon
                                                fontSize={'25px'}
                                                m="10px"
                                            ></CheckIcon>
                                        </Center>
                                        <Center>
                                            <Text fontSize={'10x'}>
                                                Envoyé !
                                            </Text>
                                        </Center>
                                    </Box>
                                )}
                            </Box>
                        )
                    })}
                <Box style={{ clear: 'both' }}></Box>
            </Box>

            <Center>
                <Button onClick={(e) => uploadFilesToStorage()} mt="20px">
                    Envoyer les documents
                </Button>
            </Center>
        </Box>
    )
}
