/*
 * Formulaire autonome et réutilisable permettant de modifier le password client.
 * 2 usages possible :
 *   - mode standard sans paramètre, l'utilisateur doit étre connecté
 *   - mode OOB, avec le code du liens fournis par mail suite a la demande réinitialisation de password.
 */

import {
    Center,
    InputGroup,
    InputLeftAddon,
    Input,
    Text,
    useToast,
    Button,
} from '@chakra-ui/react'
import { useState } from 'react'
import { confirmPasswordReset, getAuth, updatePassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

interface Props {
    withOOB?: string
}

export const UpdatePasswordClient = (props: Props) => {
    const navigate = useNavigate()
    const toast = useToast()

    const [newPassword1, setNewPassword1] = useState<string>('')
    const [newPassword2, setNewPassword2] = useState<string>('')
    const [passwordOK, setpasswordOK] = useState<boolean>(false)
    const [loading, setloading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    function passwordCheck(password1: string, password2: string) {
        console.log(password1 + '  ==? ' + password2)
        if (password1 === '' || password2 === '') {
            setpasswordOK(false)
            setError('Les deux mots de passes doivent étre renseignés')
        } else if (password1 !== password2) {
            setpasswordOK(false)
            setError('Les mots de passe saisis sont identiques')
        } else if (password1!.length < 6) {
            setpasswordOK(false)
            setError('Votre mot de passe doit contenir au minimum 6 caractères')
        } else {
            setpasswordOK(true)
            setError('')
        }
    }

    function SuccessResetValue() {
        setNewPassword1('')
        setNewPassword2('')
        setError('')
        setpasswordOK(false)
        setloading(false)
        toast({
            title: 'Mot de passe mis à jour!',
            description: 'Reconnectez vous',
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
    }

    function UpdatePassword() {
        const auth = getAuth()

        setloading(true)
        if (props.withOOB) {
            confirmPasswordReset(auth, props.withOOB, newPassword1)
                .then((data) => {
                    SuccessResetValue()
                    navigate('/login')
                })
                .catch((error) => {
                    setloading(false)
                    setError(error.message)
                })
        }
        if (!props.withOOB) {
            updatePassword(auth.currentUser!, newPassword1)
                .then(() => {
                    SuccessResetValue()
                    navigate('/logout')
                })
                .catch((error) => {
                    setloading(false)
                    setError(error.message)
                })
        }
    }
    return (
        <>
            <InputGroup mt={'30px'}>
                <InputLeftAddon children="Mot de passe" />
                <Input
                    name="newpassword1"
                    type="password"
                    onChange={(e) => {
                        setNewPassword1(e.target.value)
                        passwordCheck(e.target.value, newPassword2)
                    }}
                />
            </InputGroup>
            <InputGroup mt={'20px'}>
                <InputLeftAddon children="Vérification Mot de passe" />
                <Input
                    name="newpassword2"
                    type="password"
                    onChange={(e) => {
                        setNewPassword2(e.target.value)
                        passwordCheck(newPassword1, e.target.value)
                    }}
                />
            </InputGroup>
            {error !== '' && (
                <Center mt={'5px'} mb={'5px'}>
                    <Text color={'red'}>{error}</Text>
                </Center>
            )}
            {loading && <></>}
            {!loading && (
                <Center mt={'20px'} mb={'20px'}>
                    <Button
                        isDisabled={!passwordOK}
                        backgroundColor={'grey.900'}
                        onClick={(e) => {
                            UpdatePassword()
                        }}
                    >
                        Mise a jour du mot de passe
                    </Button>
                </Center>
            )}
        </>
    )
}
