/*
 * Formulaire autonome et réutilisable permettant de créer ou d'éditer un objet package
 */

import { db } from '../../../firebaseManager'
import {
    FormControl,
    Button,
    Input,
    InputGroup,
    InputLeftAddon,
    useToast,
    Center,
    Text,
    Checkbox,
} from '@chakra-ui/react'

import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Package } from '../../../model'
import { useContext } from 'react'
import { AuthContext } from '../../../auth/AuthContextProvider'

const validationSchema = yup.object().shape({
    nom: yup.string().required(),
    havepassword: yup.boolean().required(),
    password: yup.string(),
})

export type AddpackageValue = {
    nom: string
    havepassword: boolean
    password: string
    creationDate: number
}

export type Props = {
    package?: Package
    onSucess?: () => void
}

export const CreationEditionPackage = (props: Props) => {
    const auth = useContext(AuthContext)
    const editMode = props.package != null && props.package !== undefined
    const toast = useToast()
    const formikAddpackage = useFormik({
        validationSchema,
        initialValues: {
            nom: editMode && props.package!.nom ? props.package!.nom : '',
            havepassword:
                editMode && props.package!.havepassword
                    ? props.package!.havepassword
                    : false,
            password:
                editMode &&
                props.package!.havepassword &&
                props.package!.password
                    ? props.package!.password
                    : '',
            creationDate:
                editMode && props.package!.creationDate
                    ? props.package!.creationDate
                    : Date.now(),
        },
        onSubmit: (values: AddpackageValue, actions) => {
            if (editMode) {
                updateDoc(doc(db, 'packages/' + props.package?.id), {
                    ...values,
                })
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'package mise a jour!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on edit ' + e)
                        actions.setSubmitting(false)
                    })
            } else {
                setDoc(doc(collection(db, 'packages')), {
                    ...values,
                    utilisateurProprietaire: auth.firebaseUser?.uid,
                })
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Boite documentaire créée!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on add' + e)
                        actions.setSubmitting(false)
                    })
            }
        },
    })

    return (
        <FormikProvider value={formikAddpackage}>
            <FormControl id="nom" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Nom
                        <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        name="nom"
                        type="text"
                        onChange={formikAddpackage.handleChange}
                        value={formikAddpackage.values.nom}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="havepassword" mt={4}>
                <InputGroup>
                    <Checkbox
                        size="lg"
                        colorScheme="blue"
                        onChange={formikAddpackage.handleChange}
                        isChecked={formikAddpackage.values.havepassword}
                    >
                        Avec password ?
                    </Checkbox>
                </InputGroup>
            </FormControl>
            {formikAddpackage.values.havepassword && (
                <>
                    <FormControl id="password" mt={4}>
                        <InputGroup>
                            <InputLeftAddon>
                                Password
                                <Text color={'red'}></Text>
                            </InputLeftAddon>
                            <Input
                                name="password"
                                type="text"
                                onChange={formikAddpackage.handleChange}
                                value={formikAddpackage.values.password}
                            />
                        </InputGroup>
                    </FormControl>
                </>
            )}

            <Center>
                <Button
                    mt={4}
                    mr={3}
                    onClick={formikAddpackage.submitForm}
                    isDisabled={
                        !formikAddpackage.isValid || !formikAddpackage.dirty
                    }
                    isLoading={formikAddpackage.isSubmitting}
                >
                    {editMode ? <>Editer</> : <>Ajouter</>}
                </Button>
            </Center>
        </FormikProvider>
    )
}
