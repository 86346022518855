/*
Classe utilitaire pour gerer des affichages en fonction de certaine conditions.
Affichage si Connecté / utilisateur récupéré / role de l'utilisateur
Permet de gérer l'affache d'un élément mais ne garantie pas la sécurité des datas! 
*/

import { useContext } from 'react'
import { AuthContext } from './AuthContextProvider'

interface Props {
    children: React.ReactNode
    showIfNotConnected?: Boolean
    hideIfNotConnected?: Boolean
    showIfConnected?: Boolean
    hideIfConnected?: Boolean
}

export const ConditionalConnect = (props: Props) => {
    const authentification = useContext(AuthContext)

    // Condition lié a la connexion avec firebase
    if (authentification.firebaseUser == null && props.showIfNotConnected) {
        return <>{props.children}</>
    } else if (
        authentification.firebaseUser == null &&
        props.hideIfNotConnected
    ) {
        return <></>
    } else if (authentification.firebaseUser && props.showIfConnected) {
        return <>{props.children}</>
    } else if (authentification.firebaseUser && props.hideIfConnected) {
        return <></>
    } else {
        return <></>
    }
}
