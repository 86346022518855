import { IconButton, Spinner } from '@chakra-ui/react'
import { useState } from 'react'

import { DownloadIcon } from '@chakra-ui/icons'
import { BuildUrlDocFunction } from '../../utils/helpers'

export type Props = {
    packageId: string
    docName: string
    password: string
}
export const DownloadDocButton = ({ packageId, docName, password }: Props) => {
    const [openLoading, setOpenLoading] = useState<boolean>(false)
    const DownloadFile = async () => {
        setOpenLoading(true)

        const UrlCible = BuildUrlDocFunction(packageId, docName, password)
        fetch(UrlCible, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', docName.substring(14))

                // Append to html link element page
                document.body.appendChild(link)

                // Start download
                link.click()

                // Clean up and remove the link
                link!.parentNode!.removeChild(link)
            })
            .finally(() => {
                setOpenLoading(false)
            })
    }

    return (
        <>
            <IconButton
                ml="8px"
                aria-label=""
                fontSize={'16px'}
                color={'#57C046'}
                disabled={openLoading}
                onClick={(e) => {
                    DownloadFile()
                }}
            >
                {openLoading ? (
                    <Spinner thickness="4px" color="green"></Spinner>
                ) : (
                    <DownloadIcon></DownloadIcon>
                )}
            </IconButton>
        </>
    )
}
