import { IconButton } from '@chakra-ui/react'

import { ViewIcon } from '@chakra-ui/icons'
import { BuildUrlDocFunction } from '../../utils/helpers'

export type Props = {
    packageId: string
    docName: string
    password: string
}
export const OpenDocButtonViaFunction = ({
    packageId,
    docName,
    password,
}: Props) => {
    return (
        <>
            <IconButton
                as="a"
                href={BuildUrlDocFunction(packageId, docName, password)}
                target="_blank"
                rel="noreferrer"
                ml="8px"
                aria-label=""
                fontSize={'16px'}
                color={'#57C046'}
            >
                <ViewIcon></ViewIcon>
            </IconButton>
        </>
    )
}
