/*
 * Barre de navigation générale du site
 */
'use client'

import {
    Box,
    Flex,
    Avatar,
    HStack,
    Center,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, AddIcon } from '@chakra-ui/icons'

import { useNavigate } from 'react-router-dom'
import { ConditionalConnect } from '../../../auth/ConditionalConnect'

interface PropsLink {
    url: string
    name: string
    onSuccess?: () => void
}

const NavLink = (props: PropsLink) => {
    const navigate = useNavigate()

    const { url, name, onSuccess } = props
    return (
        <Box
            onClick={() => navigate(props.url)}
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.200', 'gray.700'),
            }}
        >
            {name}
        </Box>
    )
}
const NavLinkMenuItem = (props: PropsLink) => {
    const navigate = useNavigate()

    const { url, name, onSuccess } = props
    return <MenuItem onClick={() => navigate(props.url)}>{name}</MenuItem>
}

// export default function NavBar() {
//     return (
//         <>
//             <Center>
//                 <Box>
//                     <Flex h={'50px'} alignItems={'center'} w={'100%'}>
//                         <NavButton url="/" name={'Acceuil'} />
//                         <ConditionalConnect showIfNotConnected>
//                             <NavButton url="/login" name={'Connexion'} />
//                         </ConditionalConnect>
//                         <ConditionalConnect showIfConnected>
//                             <NavButton
//                                 url="/PackageList"
//                                 name={'Liste des packages'}
//                             />

//                             <NavButton url="/logout" name={'Me déconnecter'} />
//                         </ConditionalConnect>
//                     </Flex>
//                 </Box>
//             </Center>
//         </>
//     )
// }

export default function NavBar() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()

    return (
        <>
            <Box bg={'#1F2937'} px={4} color="white">
                <Flex
                    h={16}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <Box onClick={() => navigate('/')}>DocSender</Box>
                        <HStack
                            as={'nav'}
                            spacing={4}
                            display={{ base: 'none', md: 'flex' }}
                        >
                            {/* <ConditionalConnect showIfConnected>
                                <NavLink
                                    url="/PackageList"
                                    name={'Liste des packages'}
                                />
                            </ConditionalConnect> */}
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <ConditionalConnect showIfNotConnected>
                            <Button
                                variant={'solid'}
                                colorScheme={'teal'}
                                size={'sm'}
                                mr={4}
                                //leftIcon={<AddIcon />}
                                onClick={() => navigate('/login')}
                            >
                                Connexion
                            </Button>
                        </ConditionalConnect>
                        <ConditionalConnect showIfConnected>
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    rounded={'full'}
                                    variant={'link'}
                                    cursor={'pointer'}
                                    minW={0}
                                >
                                    <Avatar size={'sm'} src={'/people.png'} />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem
                                        color="black"
                                        onClick={() => navigate('/logout')}
                                    >
                                        Me déconnecter
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </ConditionalConnect>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {/* <ConditionalConnect showIfConnected>
                                <NavLink
                                    url="/PackageList"
                                    name={'Liste des packages'}
                                />
                            </ConditionalConnect> */}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    )
}
