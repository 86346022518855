import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { Box, Center, Container, Text } from '@chakra-ui/react'
import NavBar from '../../components/Layout/NavBar'

export const NotFound = () => {
    return (
        <>
            <NavBar />
            <Container maxW="container.lg">
                <Box
                    m="20px"
                    p="20px"
                    borderRadius={'20px'}
                    backgroundColor={'#1F2937'}
                >
                    <Center mb={'15px'}>
                        <QuestionOutlineIcon boxSize={12} />
                    </Center>
                    <Center>
                        <Text>
                            Nous ne trouvons pas cette boite documentaire.
                        </Text>
                    </Center>
                    <Center>
                        <Text>
                            Soit elle n'existe pas, soit vous ne possèdez pas
                            les droits.
                        </Text>
                    </Center>
                </Box>
            </Container>
        </>
    )
}
