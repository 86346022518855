import {
    Box,
    Text,
    useToast,
    Spinner,
    Center,
    Input,
    Button,
    HStack,
    Container,
} from '@chakra-ui/react'
import { StorageReference } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Package } from '../../model'
import axios from 'axios'
import { OpenDocButtonViaFunction } from '../../components/OpenDocButtonViaFunction'
import { DownloadDocButton } from '../../components/DownloadDocButton'
import moment from 'moment'

export const PackageViewer = () => {
    let { id } = useParams<'id'>()
    const toast = useToast()

    //package info
    const targetPackageId = id
    const [targetPackageNeedPassword, setTargetPackageNeedPassword] = useState<
        boolean | undefined
    >(undefined)
    const [targetPackagePasswordTemp, setTargetPackagePasswordTemp] =
        useState<string>('')
    const [targetPackagePassword, setTargetPackagePassword] = useState<
        string | undefined
    >(undefined)
    const [targetPackage, setTargetPackage] = useState<Package | undefined>()
    const [listDocDansStorage, setListDocDansStorage] = useState<
        StorageReference[]
    >([])

    const [loadingPackage, setLoadingPackage] = useState<boolean>(false)
    const [loadingFilesList, setLoadingFilesList] = useState<boolean>(false)

    function AskPackageNeedPassword() {
        if (!targetPackageId) {
            toast({
                title: "Information de l'id de package cible manquant",
                description: '',
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }

        axios
            .get('/api/package_need_password?packageId=' + targetPackageId)
            .then(function (response) {
                console.log(response)
                if (response.status === 200) {
                    setTargetPackageNeedPassword(response.data.needpassword)
                }
            })
            .catch(function (error) {
                setTargetPackageNeedPassword(undefined)
            })
    }
    function refreshTargetPackage() {
        if (!targetPackageId) {
            toast({
                title: "Information de l'id de package cible manquant",
                description: '',
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }

        // eslint-disable-next-line no-labels
        setLoadingPackage(true)
        setLoadingFilesList(true)

        axios
            .get(
                '/api/get_package?packageId=' +
                    targetPackageId +
                    '&password=' +
                    targetPackagePassword
            )
            .then(function (response) {
                console.log(response)
                if (response.status === 403) {
                    setTargetPackagePassword(undefined)
                    setTargetPackagePasswordTemp('')
                }
                if (response.status === 200) {
                    setTargetPackage(response.data)
                    ListFilesOnStorage()
                }
            })
            .catch(function (error) {
                setTargetPackage(undefined)
                setTargetPackagePassword(undefined)
                setTargetPackagePasswordTemp('')
                setLoadingFilesList(false)

                console.log(error)
            })
            .finally(function () {
                setLoadingPackage(false)
            })
    }

    function ListFilesOnStorage() {
        if (!targetPackageId) {
            toast({
                title: "Information de l'id de package cible manquant",
                description: '',
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
        console.log('list files')
        setLoadingFilesList(true)
        axios
            .get(
                '/api/get_package_docs?packageId=' +
                    targetPackageId +
                    '&password=' +
                    targetPackagePassword
            )
            .then(function (response) {
                console.log(response)
                if (response.status === 200) {
                    setListDocDansStorage(response.data)
                } else {
                    setListDocDansStorage([])
                }
            })
            .catch(function (error) {
                setListDocDansStorage([])
                console.log(error)
            })
            .finally(function () {
                setLoadingFilesList(false)
            })
    }

    useEffect(() => {
        if (targetPackageNeedPassword === undefined) {
            AskPackageNeedPassword()
        } else if (
            !targetPackageNeedPassword ||
            (targetPackageNeedPassword && targetPackagePassword !== undefined)
        ) {
            refreshTargetPackage()
        }
    }, [targetPackageNeedPassword, targetPackagePassword])

    return (
        <Container maxW="container.lg">
            {/** ICI ON affiche le waiting pour l'initialisation*/}

            {targetPackageNeedPassword === undefined && (
                <>
                    <Box
                        m="20px"
                        p="20px"
                        borderRadius={'20px'}
                        backgroundColor={'#1F2937'}
                    >
                        <Center mb={'15px'}>
                            <Spinner size={'xl'}></Spinner>
                        </Center>
                        <Center>
                            <Text>
                                Initialisation de la boite documemtaire...
                            </Text>
                        </Center>
                    </Box>
                </>
            )}
            {/** ICI ON DEMANDE LE PASSWORD */}
            {targetPackageNeedPassword === true &&
                targetPackagePassword === undefined && (
                    <>
                        <Box
                            m="20px"
                            p="30px"
                            borderRadius={'20px'}
                            backgroundColor={'#1F2937'}
                        >
                            <Center mb="50px">
                                <Text fontSize={'30px'}>
                                    Un mot de passe est nécessaire pour accèder
                                    aux documents
                                </Text>
                            </Center>
                            <HStack>
                                <Text w={'160px'}>Mot de passe :</Text>
                                <Input
                                    placeholder="Mot de passe"
                                    variant="outline"
                                    type="password"
                                    value={targetPackagePasswordTemp}
                                    onChange={(e) =>
                                        setTargetPackagePasswordTemp(
                                            e.target.value
                                        )
                                    }
                                />
                                <Button
                                    onClick={() =>
                                        setTargetPackagePassword(
                                            targetPackagePasswordTemp
                                        )
                                    }
                                >
                                    Ouvrir
                                </Button>
                            </HStack>
                        </Box>
                    </>
                )}

            {/** ICI ON AFFICHE LE CONTENU SI PAS DE PASSWORD OU SI PASSWORD RENSEIGNE */}
            {(targetPackageNeedPassword === false ||
                (targetPackageNeedPassword === true &&
                    targetPackagePassword !== undefined)) && (
                <>
                    {loadingPackage && (
                        <>
                            <Box
                                m="20px"
                                p="20px"
                                borderRadius={'20px'}
                                backgroundColor={'#1F2937'}
                            >
                                <Center mb={'15px'}>
                                    <Spinner size={'xl'}></Spinner>
                                </Center>
                                <Center>
                                    <Text>
                                        Chargement des informations de la boite
                                        documemtaire ...
                                    </Text>
                                </Center>
                            </Box>
                        </>
                    )}

                    {!loadingPackage && (
                        <>
                            <Center>
                                <Text fontSize={'40px'}>
                                    {targetPackage?.nom}
                                </Text>
                            </Center>
                            <Box
                                m="20px"
                                p="20px"
                                borderRadius={'20px'}
                                backgroundColor={'#1F2937'}
                            >
                                <Text fontSize={'20px'} mb={'20px'}>
                                    Information de la boite documentaire{' '}
                                </Text>
                                <Text>
                                    Identifiant unique: {targetPackage?.id}
                                </Text>
                                <Text>
                                    Créé le{' '}
                                    {moment(targetPackage?.creationDate).format(
                                        'DD-MM-YYYY à hh:mm:ss'
                                    )}
                                </Text>
                            </Box>
                        </>
                    )}

                    {loadingFilesList && (
                        <>
                            <Box
                                m="20px"
                                p="20px"
                                borderRadius={'20px'}
                                backgroundColor={'#1F2937'}
                            >
                                <Center mb={'15px'}>
                                    <Spinner size={'xl'}></Spinner>
                                </Center>
                                <Center>
                                    <Text>
                                        Chargement des documents du package ...
                                    </Text>
                                </Center>
                            </Box>
                        </>
                    )}

                    {!loadingFilesList && (
                        <Box
                            m="20px"
                            p="20px"
                            borderRadius={'20px'}
                            backgroundColor={'#1F2937'}
                        >
                            <Text fontSize={'20px'} mb={'20px'}>
                                Liste des documents existants dans la boite
                            </Text>
                            {listDocDansStorage.length === 0 && (
                                <>Aucun documents disponibles</>
                            )}
                            {listDocDansStorage.length > 0 &&
                                listDocDansStorage.map((doc, index) => {
                                    const fullPath = doc.name
                                    const nameOnly = fullPath.replace(
                                        'documents/' + targetPackageId + '/',
                                        ''
                                    )
                                    const nameOnlyWithoutTimeStamp =
                                        nameOnly.substring(14)

                                    return (
                                        <Box ml="10px" mb="5px" key={fullPath}>
                                            {nameOnlyWithoutTimeStamp}

                                            <OpenDocButtonViaFunction
                                                packageId={targetPackageId!}
                                                docName={nameOnly}
                                                password={
                                                    targetPackagePassword
                                                        ? targetPackagePassword
                                                        : ''
                                                }
                                            />
                                            <DownloadDocButton
                                                packageId={targetPackageId!}
                                                docName={nameOnly}
                                                password={
                                                    targetPackagePassword
                                                        ? targetPackagePassword
                                                        : ''
                                                }
                                            />
                                        </Box>
                                    )
                                })}
                        </Box>
                    )}
                </>
            )}
        </Container>
    )
}
