import {
    IconButton,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useToast,
} from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { Package } from '../../model'
import { CreationEditionPackage } from '../Formulaire/CreationEditionPackage'

export type Props = {
    packageItem: Package
    onSucess: () => void
}
export const ModifyPackageButton = ({ packageItem, onSucess }: Props) => {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <IconButton
                float={'right'}
                onClick={() => onOpen()}
                ml="8px"
                aria-label=""
                fontSize={'16px'}
                color={'white'}
                backgroundColor={'#111827'}
            >
                <EditIcon></EditIcon>
            </IconButton>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Modification du mot de passe d'accès
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <CreationEditionPackage
                            package={packageItem}
                            onSucess={() => {
                                onSucess()
                                onClose()
                                toast({
                                    title: 'La boite a été mise a jour',
                                    description: '',
                                    status: 'success',
                                    duration: 2000,
                                    isClosable: true,
                                })
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
