import { IconButton, Spinner, useToast, Text } from '@chakra-ui/react'
import { ref, getDownloadURL } from 'firebase/storage'
import { useEffect, useState } from 'react'

import { ViewIcon } from '@chakra-ui/icons'
import { storage } from '../../firebaseManager'

export type Props = {
    fullPath: string
    precharge?: boolean
}
export const OpenDocButton = ({ fullPath, precharge = false }: Props) => {
    const toast = useToast()
    const [openLoading, setOpenLoading] = useState<boolean>(false)
    const [urlCible, setUrlCible] = useState<string | undefined>(undefined)
    const OpenFile = async (fullPath: string) => {
        if (urlCible) {
            window.open(urlCible, '_blank')
        } else {
            setOpenLoading(true)
            getDownloadURL(ref(storage, fullPath))
                .then((downloadURL) => {
                    window.open(downloadURL, '_blank')
                    setOpenLoading(false)
                })
                .catch((error) => {
                    toast({
                        title: "Le document n'a pas peu étre récupéré",
                        description: '',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    })
                    setOpenLoading(false)
                })
        }
    }
    useEffect(() => {
        if (precharge) {
            getDownloadURL(ref(storage, fullPath)).then((downloadURL) => {
                setUrlCible(downloadURL)
            })
        }
    }, [])
    return (
        <>
            <IconButton
                ml="8px"
                aria-label=""
                fontSize={'16px'}
                color={'#57C046'}
                disabled={openLoading}
                onClick={(e) => {
                    console.log('open button click : ' + fullPath)
                    OpenFile(fullPath)
                }}
            >
                {openLoading ? (
                    <Spinner thickness="4px" color="green"></Spinner>
                ) : (
                    <ViewIcon></ViewIcon>
                )}
            </IconButton>
        </>
    )
}
