import { IconButton, Spinner, useToast, Text } from '@chakra-ui/react'
import { ref, deleteObject } from 'firebase/storage'
import { useState } from 'react'

import { DeleteIcon } from '@chakra-ui/icons'
import { storage } from '../../firebaseManager'

export type Props = {
    fullPath: string
    onDelete: () => void
}
export const DeleteDocButton = ({ fullPath, onDelete }: Props) => {
    const toast = useToast()
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [deleteSuccess, setDeleteSucess] = useState<boolean>(false)
    const DeleteFile = async (fullPath: string) => {
        setDeleteLoading(true)
        console.log('delete : ' + fullPath)
        const deletRef = ref(storage, fullPath)
        deleteObject(deletRef)
            .then(() => {
                onDelete()
                toast({
                    title: 'Le document a été supprimé',
                    description: '',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                })
                setDeleteSucess(true)
                setDeleteLoading(false)
            })
            .catch((error) => {
                toast({
                    title: "Le document n'a pas été supprimé",
                    description: '',
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
                setDeleteLoading(false)
            })
    }
    return (
        <>
            {deleteSuccess ? (
                <Text mx={'15px'} as="span" color={'red'}>
                    Supprimé
                </Text>
            ) : (
                <IconButton
                    ml="8px"
                    aria-label=""
                    fontSize={'16px'}
                    color={'#C05746'}
                    disabled={deleteLoading}
                    onClick={(e) => {
                        console.log('delete button click : ' + fullPath)
                        DeleteFile(fullPath)
                    }}
                >
                    {deleteLoading ? (
                        <Spinner thickness="4px"></Spinner>
                    ) : (
                        <DeleteIcon></DeleteIcon>
                    )}
                </IconButton>
            )}
        </>
    )
}
