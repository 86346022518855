import {
    IconButton,
    Spinner,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    Button,
} from '@chakra-ui/react'
import { useState } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import { DeletePackage } from '../../firebaseManager/Communicator/PackageCommunicator'

export type Props = {
    packageId: string
    packageName: string
    onDelete: () => void
}
export const DeletePackageButton = ({
    packageId,
    packageName,
    onDelete,
}: Props) => {
    const toast = useToast()
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const DeletePack = async () => {
        setDeleteLoading(true)
        DeletePackage(packageId)
            .then(() => {
                onDelete()
                toast({
                    title: 'La boite a été supprimé',
                    description: '',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                })
            })
            .finally(() => {
                setDeleteLoading(false)
            })
    }
    return (
        <>
            <IconButton
                onClick={(e) => {
                    onOpen()
                }}
                ml="8px"
                aria-label=""
                fontSize={'16px'}
                color={'red'}
                backgroundColor={'white'}
            >
                {deleteLoading ? (
                    <Spinner thickness="4px"></Spinner>
                ) : (
                    <DeleteIcon></DeleteIcon>
                )}
            </IconButton>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Suppression de boite</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Voulez vous vraiment supprimer la boite "
                        <b>{packageName}</b>" ainsi que tout son contenu ?
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="red"
                            mr={3}
                            onClick={() => {
                                onClose()
                                DeletePack()
                            }}
                        >
                            Supprimer
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            Annuler
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
